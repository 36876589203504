import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn, validateTokenRefreshData } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import TwoFA_middleware from "@/auth/middleware/auth";

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  
  routes: [
    ...apps,
    ...dashboard,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {  
  const isLoggedIn = await isUserLoggedIn()
  if (!isLoggedIn && to.name != 'auth-login') next({ name: 'auth-login' })
  if (!isLoggedIn && to.name === 'auth-login') next()
  if (isLoggedIn && to.name === 'dashboard') next()

  // If route has numbers, it is not find in permissions
  var regex = /(\d+)/g  // d: numbers  g: global (al the strig)
  var routeWithIdNumber = to.path.match(regex)
  
  // if (routeWithIdNumber === null) {
  //   const permissions = JSON.parse(sessionStorage.getItem('permissions'))
  //   if (permissions) {
  //     var accessAllowed = permissions.find(permission => permission.path == to.path)
  //     if (accessAllowed) {
  //       if (accessAllowed.path != '') next()
  //     }
  //   }
  // }else {
  //   next()
  // }
  next()

  // if (!isLoggedIn) next({ name: 'auth-login' })
  // next()

 })

router.beforeEach(TwoFA_middleware)

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
