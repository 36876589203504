import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Carrousel from '@/views/components/homepage/sections/carrousel.vue'
import Banner from '@/views/components/homepage/sections/banner.vue'
import FeactureSection from '@/views/components/homepage/sections/feacture-section.vue'
import ClientSection from '@/views/components/homepage/sections/client-section.vue'
import ProviderSection from '@/views/components/homepage/sections/provider-section.vue'
import Promo from '@/views/components/homepage/sections/promo'
import slider from '@/views/components/homepage/widgets/slider/slider'
import container from '@/views/components/homepage/widgets/container/container'

Vue.component(FeatherIcon.name, FeatherIcon)

// filter for currency
import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,
  {
    symbol : '$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  })
Vue.component('w-slider', slider)
Vue.component('w-container', container)
Vue.component('Carrousel', Carrousel)
Vue.component('Banner', Banner)
Vue.component('ClientSection', ClientSection)
Vue.component('FeactureSection', FeactureSection)
Vue.component('ProviderSection', ProviderSection)
Vue.component('Promo', Promo)
import money from 'v-money'
Vue.use(money, {precision: 2,  masked: false})
