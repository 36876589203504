<template>
  <div class="-container-" ref="container" :style="phone ? phone.styles : ''">
    <slot></slot>
  </div>
</template>
<script>
export default{
  props:{
    phone:Object,
    tablet:Object,
    laptop:Object,
  },
  mounted(){
    window.onresize=(ev)=>{
      const width = window.clientSize
      if(width<350){
        this.$refs.container.style = this.phone
      } else if (width<750){
        this.$refs.container.style = this.tablet
      } else if (width>1024){
        this.$refs.container.style = this.laptop
      }
    }
  }
}
</script>
<style>
.-container-{
  display: flex;
}
</style>