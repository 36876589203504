<template>
<section class="clients-section  centred" :class="ClassContainer" v-if="!hide" :style="Style">
  <div class="container sec-title center">
    <div class="col-lg-12 title_img col-md-12 col-sm-12 title_img mb-3">
      <img :src="img" alt="">
    </div>
    <div style="width:100%">
      <!-- Using the slider component -->
      <slider ref="slider" :options="options" style=" transition: all 3s ease 0s;">
          <!-- slideritem wrapped package with the components you need -->
          <slideritem v-for="(item,index) in clients" :key="index" id='itemSlide'>
            <figure class="image-box">
              <div :href="item.href" class="link hover-opacity">
                <img :src="item.img" alt="" id="imgFigure" draggable="false" ondragstart="return false;">
              </div>
            </figure>
          </slideritem>
          <!-- Customizable loading -->
          <div slot="loading">loading</div>
      </slider>
 </div>
  </div>
</section>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
export default {
  name:"ClientSection",
  components: {
    slider,
    slideritem
  },
  props:['hide', 'img', "'class'", 'background_color','background_image', 'clients',"'style'"],
  data () {
   return {
     options: {
      loopedSlides:4,
      speed: 3000,
      loop: true,
      effect:'nest',
      pagination: false

     }
   }
 },
  computed:
  {
    Style()
    {
      return {
        "background-color": this.background_color ? this.background_color : '',
        "background-image": this.background_image ? `url(${this.background_image})` :'',
        ...this.style
      }
    },
    ClassContainer()
    {
      return this.class

    },
  }
}
</script>
<style scoped>

.clients-section .sec-title {
    margin-bottom: 93px;
}
.title_img img {
    margin: auto;
    margin-top: auto;
    display: block;
    margin-top: -55px;
}
#itemSlide
{
  width: 23.5%;
  margin-right: 2%;
  touch-action: pan-y;
  user-select: none;
}
#imgFigure{
   user-select: none;
display: block;
width: 100%;
transform-style: preserve-3d;
transition: all 300ms ease;

}
.hover-opacity:hover{
  opacity:0.6;
}
.slider-touch{
  touch-action: manipulation;
}
</style>
