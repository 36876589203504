import axios from '@axios'

export default {
    // index({ commit }, payload) {
    //     return new Promise((resolve, reject) => {
    //         axios.get('/api/schools', payload)
    //             .then(res => {
    //                 commit('SET_SCHOOLS', res.data)
    //                 resolve(res)
    //             })
    //             .catch(err => {
    //                 reject(err)
    //             })
    //     }
    //     )
    // },
}