<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    centered
    visible
    size="md"
    hide-footer
    hide-header-close
    :visible="showWithdrawalByPinModal"
  >
    <template #default="{ hide }">
      <div class="text-center w-100">
        <b-card class="shadow-none">
          <div class="pt-3 pb-1 px-sm-4 px-md-2 px-lg-5">
            <div class="svg-icon svg-icon-xl text-purple">
              <b-avatar variant="secondary" size="60">
                <feather-icon size="25" icon="LockIcon" />
              </b-avatar>
            </div>
            <h3 class="fw-normal text-dark mt-2">{{ $t("2FA") }}</h3>
            <section style="opacity: 0.8">
              <p v-if="componentType === 'EMAIL'">
                {{ $t("email_verification") }}
              </p>
              <p v-else>{{ $t("app_verification") }}</p>
            </section>
            <section class="mt-4 mb-1">
              <p v-if="componentType === 'EMAIL'">
                {{ $t("email_code_text") }}
                {{ userData().email | partialEmail }}
              </p>
              <p v-else>{{ $t("app_verification_enter_token") }}</p>
            </section>

            <b-form-row class="mt-4 pt-2">
              <b-col>
                <input
                  type="text"
                  class="form-control form-control-lg text-center"
                  maxlength="1"
                  autofocus=""
                  ref="input1"
                  v-model="values[0]"
                />
              </b-col>
              <b-col>
                <input
                  type="text"
                  class="form-control form-control-lg text-center"
                  maxlength="1"
                  ref="input2"
                  v-model="values[1]"
                />
              </b-col>
              <b-col>
                <input
                  type="text"
                  class="form-control form-control-lg text-center"
                  maxlength="1"
                  ref="input3"
                  v-model="values[2]"
                />
              </b-col>
              <b-col>
                <input
                  type="text"
                  class="form-control form-control-lg text-center"
                  maxlength="1"
                  ref="input4"
                  v-model="values[3]"
                />
              </b-col>
              <b-col>
                <input
                  type="text"
                  class="form-control form-control-lg text-center"
                  maxlength="1"
                  ref="input5"
                  v-model="values[4]"
                />
              </b-col>
              <b-col>
                <input
                  type="text"
                  class="form-control form-control-lg text-center"
                  maxlength="1"
                  ref="input6"
                  v-model="values[5]"
                />
              </b-col>
            </b-form-row>

            <section>
              <b-spinner
                style="margin-top: 35px !important"
                small
                v-if="verifyRequestLoading"
              ></b-spinner>
              <a
                v-else
                :class="{ disabled: !codeEntered }"
                class="btn btn-purple btn-lg w-100 hover-lift-light mt-4"
                @click="handleVerificationCode"
              >
                {{ $t("verify") }}
              </a>
            </section>
          </div>
        </b-card>

        <p
          class="text-center text-muted"
          v-if="!generateCodeRequestLoading && componentType === 'EMAIL'"
        >
          {{ $t("email_verification_code_question") }}
          <a
            href="#"
            @click.p.prevent="handleGenerationCode"
            class="text-decoration-none ms-2"
          >
            {{ $t("resend") }}
          </a>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { BAvatar, BButton, BModal } from "bootstrap-vue";
import { mapActions, mapMutations, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import { translatableText } from "@core/utils/utils";
import { connectSocket } from "@/libs/socket-io/socket";
import { getUserData, SET_USER_DATA } from "@/auth/utils";

export default {
  props: ["config"],
  name: "TwoFA",
  components: { BAvatar, BButton, BModal, vSelect },
  data() {
    return {
      values: ["", "", "", "", "", ""],
      prevValues: ["", "", "", "", "", ""],
      inputRefs: ["input1", "input2", "input3", "input4", "input5", "input6"],
      isPasting: false,
    };
  },
  filters: {
    partialEmail: function (email) {
      return email
        ? email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")
        : "";
    },
  },
  watch: {
    values(newValues) {
      if (!this.isPasting) {
        console.log(newValues);
        // Comparar con la copia anterior
        this.prevValues.forEach((prevVal, index) => {
          let newVal = newValues[index];
          console.log(newVal, prevVal);
          if (!newVal && prevVal) {
            // input was cleared
            // alert("borrado");
            let prevRef = this.inputRefs[Math.max(0, index - 1)];
            this.$refs[prevRef].focus();
          } else if (newVal !== prevVal) {
            // input was changed
            // alert("cambiado");
            this.goToNext();
          }
        });
      }
      // Guardar nuevos valores
      this.prevValues = JSON.parse(JSON.stringify(newValues));
    },
  },
  computed: {
    ...mapState("verification", [
      "modal",
      "verifyRequestLoading",
      "componentType",
      "generateCodeRequestLoading",
      "modalRedirect",
    ]),

    showWithdrawalByPinModal: {
      set: function (value) {
        this.SET_VERIFICATION_MODAL(value);
      },
      get: function () {
        return this.modal;
      },
    },
    buttonStyle() {
      return {
        color: this.config.text_color ? this.config.text_color : "white",
      };
    },
    color() {
      if (this.config) {
        return this.config.color ? this.config.color : "primary";
      }
      return "primary";
    },
    codeEntered() {
      return this.values.every((number) => number !== "");
    },
  },
  methods: {
    ...mapActions("verification", ["verify2FACode", "generate2FAEmail"]),
    ...mapMutations("verification", ["SET_VERIFICATION_MODAL"]),
    resetValues() {
      this.values = ["", "", "", "", "", ""];
    },
    userData() {
      return getUserData();
    },
    async handleVerificationCode() {
      try {
        const verification = await this.verify2FACode({
          code: this.values.join(""),
        });
        if (verification) {
          this.notify(this.$t("2FA"), this.$t("code_success"), "success");
          this.resetValues();
          SET_USER_DATA({
            ...this.userData(),
            was2FASucceedValidate: true,
          });
          //socket-io connect to server
          connectSocket();
          if (this.modalRedirect) this.$router.push(this.modalRedirect);
          else this.$router.push({ name: "dashboard" });
          this.SET_VERIFICATION_MODAL(false);
        } else {
          this.notify(this.$t("2FA"), this.$t("invalid_code"), "danger");
        }
      } catch (e) {
        this.notify(
          this.$t("2FA"),
          await translatableText({ text: axiosErrorHandle(e) }),
          "danger"
        );
      }
    },
    async handleGenerationCode() {
      try {
        if (this.componentType === "EMAIL") {
          await this.generate2FAEmail();
          this.notify(this.$t("2FA"), this.$t("code_success_send"), "success");
        }
      } catch (error) {
        console.log(error);
      }
    },
    notify(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t(title),
          icon: "LockIcon",
          text: text.toString(),
          variant: variant,
        },
      });
    },
    goToNext() {
      let currentIndex = this.inputRefs.findIndex(
        (ref) => this.$refs[ref] === document.activeElement
      );

      if (currentIndex < this.inputRefs.length - 1) {
        let nextRef = this.inputRefs[currentIndex + 1];
        this.$nextTick(() => {
          this.$refs[nextRef].focus();
        });
      }
    },
    pasteClipboard(event) {
      this.isPasting = true;
      console.log(this.isPasting);
      // Obtener contenido del portapapeles
      let clipboardContent = event.clipboardData.getData("Text");
      let numbers = clipboardContent.split("");
      // Pegar cada número en su input
      for (let index = 0; index < numbers.length; index++) {
        if (this.values[index] !== undefined) {
          this.values[index] = numbers[index];
        }
      }
      setTimeout(() => {
        this.isPasting = false;
      }, 10);
    },
  },
  mounted() {
    window.addEventListener("paste", this.pasteClipboard);
  },

  beforeDestroy() {
    window.removeEventListener("paste", this.pasteClipboard);
  },
};
</script>

<style scoped>
.hover-lift-light {
  transition: box-shadow 0.25s ease, transform 0.25s ease, color 0.25s ease,
    background-color 0.15s ease-in;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.8rem 1.85rem;
  font-size: 1.1rem;
  border-radius: 0.3rem;
}

.btn-purple {
  color: #fff;
  background-color: #6672e8;
  border-color: #6672e8;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 4rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.65rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2e50;
  background-color: #f6f9fc;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card-body {
  padding: 0.5rem !important;
}
</style>
