export default {
    SET_HEADERS(state, payload) {
        console.log(payload.headers, "payload")
        state.headers = payload
    },
    SET_MENUS(state, payload) {
        state.menus = payload
    },
    SET_HOMEPAGES(state, payload) {
        state.homePages = payload
    },
    SET_FOOTERS(state, payload) {
        state.footers = payload
    }
    

}