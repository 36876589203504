import axios from "@axios";

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    setLimit(context, { url, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`system-limitacion/${url}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateLimit(context, { url, payload, limitId }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`system-limitacion/${url}/${limitId}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLimit(context, { url, id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`system-limitacion/${url}`, {
            params: { clientId: id },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
