import axios from "@/libs/axios";

export default {

    getUserSessions(context, filter) {

        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.VUE_APP_URL}/user-session`, {params: filter}
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
}