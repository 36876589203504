import axios from '@axios'

export default {
    index({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/audit/show', payload)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        }
        )
    },
    getActionAndModuleEnum({ commit }) {
        return new Promise((resolve, reject) => {
              axios.get('/audit/action-module-enum')
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
          }
        )
    },
}