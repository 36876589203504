import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import whitelabelCurrencyNabvar from './whitelabel-currency-nabvar'

import user from './user'
import wallet from './wallet'
import currency from './currency'
import client from './client'
import roles from './roles'
import templates from './templates'
import hierarchyModule from '@/@core/components/HierarchyUsers/hierarchyStoreModule'
import moduleAudit from './audit'
import socketIoStore from "@/store/socket-io/socket-io.store";
import currencyLimitation from "@/store/currencyLimitation";
import verification from "@/store/verification";
import payment  from "@/store/payment";



//builder elementor
import builderSidebar from "./builder_elementor/modules/builder-sidebar.js";
import page from "./builder_elementor/modules/page.js";
import layouts from "./builder_elementor/modules/layouts.js";
import homepage from '@/views/components/whitelabel-templates/App/config/PageEdit/HomePageManagement/external/homePages/homePageStoreModule'
import exclusion from "@/store/exclusion";
import player from "@/store/player";
import game from "@/store/game";
import reward from "@/store/reward";
import product from "@/store/product";
Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    head:''
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    wallet,
    currency,
    roles,
    client,
    moduleAudit,
    homepage,
    'app-ecommerce': ecommerceStoreModule,
    'moduleRoles': roles,
    whitelabelCurrencyNabvar,
    'templatesModule': templates,
    hierarchyModule,
    builderSidebar,
    page,
    layouts,
    socketIoStore,
    currencyLimitation,
    exclusion,
    player,
    game,
    reward,
    product,
    verification,
    payment
  },
  strict: process.env.DEV,
})
