
import state from './rolesState.js'
import mutations from './rolesMutations.js'
import actions from './rolesActions.js'
import getters from './rolesGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

