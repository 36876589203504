//npm i --save @google-cloud/translate
import wordsExcluded from "@/libs/google-translate/wordsExcluded";
const { Translate } = require("@google-cloud/translate").v2;
const translate = new Translate({
  key: "AIzaSyCn-zVGTAv9gBWspm3zGmFcMGCTYhEH8MA",
});

export const gTranslate = async (text, lang) => {
  try {
    return await translateIgnoringWords(text, lang);
  } catch (e) {
    return new Promise((resolve,rejected) => rejected({ text, e }));
  }
};

async function translateIgnoringWords(text, lang) {
  //We divide the text into words separated by space
  const words = text.split(" ");
  //final result
  let result = [];
  //accumulated words
  let phrase = "";
  for (const word of words) {
    // If the word is in the ignore array, we return the same word without changes
    if (wordsExcluded.includes(word.toLowerCase())) {
      //before returning the word without change we translate the words that we have accumulated
      if (phrase.length > 0) {
        const [t] = await translate.translate(phrase, lang);
        result.push(t);
        phrase = "";
      }
      //we return the word unchanged
      result.push(word);
    } else {
      phrase += " " + word;
    }
  }
  //If any phrase remains to be translated, we will finally translate it
  if (phrase.length > 0) {
    const [t] = await translate.translate(phrase, lang);
    result.push(t);
  }
  // We join again all the words in a single string, separated by space.
  return result.join(" ");
}
