// src/utils/toastUtils.js

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

/**
 * Shows a toast notification with the given parameters.
 * @param {Object} options - The options for the toast notification.
 * @param {string} options.title - The title of the toast.
 * @param {string} options.text - The main text content of the toast.
 * @param {string} options.icon - The icon to display in the toast.
 * @param {string} options.variant - The variant/style of the toast (e.g., 'success', 'danger').
 */
export const showToast = ({ title, text, icon, variant, toast }) => {
  toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      icon,
      variant,
    },
  });
};

/**
 * Shows a success toast notification.
 * @param {string} title - The title of the toast.
 * @param {string} text - The main text content of the toast.
 */
export const showSuccessToast = (toast, title, text) => {
  showToast({
    title,
    text,
    icon: "CheckCircleIcon",
    variant: "success",
    toast,
  });
};

/**
 * Shows an error toast notification.
 * @param {string} title - The title of the toast.
 * @param {string} text - The main text content of the toast.
 */
export const showErrorToast = (toast, title, text) => {
  showToast({
    title,
    text,
    icon: "AlertTriangleIcon",
    variant: "danger",
    toast,
  });
};
