export default {
  SET_VERIFICATION_MODAL(state, flag) {
    state.modal = flag;
  },
  SET_MODAL_REDIRECT(state,url) {
    state.modalRedirect = url
  },
  SET_VERIFY_REQUEST_LOADING(state, flag) {
    state.verifyRequestLoading = flag;
  },
  SET_GENERATE_CODE_REQUEST_LOADING(state, flag) {
    state.generateCodeRequestLoading = flag;
  },
  SET_COMPONENT_TYPE(state, componentType) {
    state.componentType = componentType;
  },
  SET_2FA_CHANGE_STATUS_LOADING(state, flag) {
    state.change2FAStatusLoading = flag;
  },
};
