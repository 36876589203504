import axios from '@/libs/axios'
// const APP_URL = process.env.VUE_APP_URL;

export default {
  verify2FACode(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("SET_VERIFY_REQUEST_LOADING", true);
      axios
        .post(`verify-2fa`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("SET_VERIFY_REQUEST_LOADING", false);
        });
    });
  },
  generate2FAEmail(context) {
    return new Promise((resolve, reject) => {
      context.commit("SET_GENERATE_CODE_REQUEST_LOADING", true);
      axios
        .post(`generate-2fa-email`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("SET_GENERATE_CODE_REQUEST_LOADING", false);
        });
    });
  },
  generate2FASecretApp(context) {
    return new Promise((resolve, reject) => {
      context.commit("SET_GENERATE_CODE_REQUEST_LOADING", true);
      axios
        .post(`generate-2fa-secret-app `)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("SET_GENERATE_CODE_REQUEST_LOADING", false);
        });
    });
  },
  set2FAStatus(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("SET_2FA_CHANGE_STATUS_LOADING", true);
      axios
        .post(`set-2FA-status`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("SET_2FA_CHANGE_STATUS_LOADING", false);
        });
    });
  },
};
