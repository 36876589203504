import axios from '@/libs/axios'
export default {
  // <---------------------------ROLES----------------------------->
  getRoles({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/roles')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  showRole({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/roles/${payload}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addRole({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/roles', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateRole ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/roles/' + payload._id, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteRole ({ commit }, _id) {
    return new Promise((resolve, reject) => {
      axios.delete('/roles/' + _id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getModules({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/modules')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addModule ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/modules', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateModule ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/modules/' + payload._id, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteModule ({ commit }, _id) {
    return new Promise((resolve, reject) => {
      axios.delete('/modules/' + _id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


















  getModelosFactura ({ commit }, payload) {
    // commit('SET_MODELOS_FACTURA', {})
    return new Promise((resolve, reject) => {
      axios.get('/api/configuracion/modelosfacturas')
        .then((response) => {
          commit('SET_MODELOS_FACTURA', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addModeloFactura ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/configuracion/modelosfacturas', payload)
        .then((response) => {
          // commit('ADD_MODELO_FACTURA',response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  showModeloFactura ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/configuracion/modelosfacturas/' + payload)
        .then((response) => {
          commit('SHOW_MODELO_FACTURA',response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateModeloFactura ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/configuracion/modelosfacturas/' + payload.id, payload)
        .then((response) => {
          commit('UPDATE_MODELO_FACTURA',response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteModeloFactura ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete('/api/configuracion/modelosfacturas/' + id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
