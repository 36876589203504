import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    namespaced: true,
    state: {
        headers: [],
        menus: [],
        homePages: [],
        footers: [],
    },
    mutations,
    actions,
    getters
}
