export default {
    SELECT_WHITELABEL(state, whitelabelSelect ) {

        if(whitelabelSelect == null){
            state.whitelabel = null
            state.currency = null
            return false;
        }

        state.whitelabel = whitelabelSelect.whitelabel

        if(whitelabelSelect.userLogueado.typeUser !== 'Root'){
            const currencyUserLogueado = whitelabelSelect.userLogueado.currencies || []
            const currencyWhitelabel = whitelabelSelect.whitelabel?.currencies || []
            const CurrencyFilter =  currencyWhitelabel.filter(currency => currencyUserLogueado.includes(currency))

            if(CurrencyFilter.length > 0){
                state.currency = CurrencyFilter[0]
            }
        }else{
            state.currency = whitelabelSelect.whitelabel?.currencies[0]
        }
    },
    SELECT_CURRENCY(state, currency) {
        state.currency = currency
    },
    SET_INFO_USER(state, userInfo){
        state.userInfo = userInfo
    },
    SET_WHITELABELS(state, whitelabels){
        if(state.whitelabel == null){
            state.whitelabel = whitelabels[0] || null
        }
        state.whitelabelsOptions = whitelabels
    },
    SET_WHITELABEL_BY_NAME(state, name){
        const whitelabels = state.whitelabelsOptions
        const foundWhitelabel = whitelabels.find(whitelabel => whitelabel.name === name)
        if (foundWhitelabel)
            state.whitelabel =foundWhitelabel
    },
}