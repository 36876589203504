import axios from '@axios'

export default {
    indexHeaders({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/headers', {params: payload})
                .then(res => {
                    commit('SET_HEADERS', res.data)
                    resolve(res)
                }).catch(err => {
                reject(err)
            })
        })
    },
    indexMenu({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/menus', {params: payload})
                .then(res => {
                    commit('SET_MENUS', res.data)
                    resolve(res)
                }).catch(err => {
                reject(err)
            })
        })
    },
    indexHomePages({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/homepages', {params: payload})
                .then(res => {
                    commit('SET_HOMEPAGES', res.data)
                    resolve(res)
                }).catch(err => {
                reject(err)
            })
        })
    },
    indexFooters({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/footers', {params: payload})
                .then(res => {
                    commit('SET_FOOTERS', res.data)
                    resolve(res)
                }).catch(err => {
                reject(err)
            })
        })
    },

    getLobby({commit}, id, params) {

        return new Promise((resolve, reject) => {
            axios.get(
                `${process.env.VUE_APP_URL}/lobbies/${id}`, {params}
            ).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    indexLobbies({commit}, params) {
        return new Promise((resolve, reject) => {
            axios.get(
                `${process.env.VUE_APP_URL}/lobby`, {params}
            ).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        })

    }
}