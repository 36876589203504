import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  namespaced: true,

  state: {
    isConnected: false,
    socketMessage: "",
    usersOnlineId: [],
    revocationNotification: [],
  },

  mutations: {
    SOCKET_connect(state, server) {
      console.log("socket connected");
      // console.log("socket connected message", JSON.stringify(server));
      state.socketMessage = server;
      state.isConnected = true;
    },
    SOCKET_events(state, server) {
      console.log(
        "socket events ::::se conecta o se desconecta un cliente::::"
      );
      console.log("socket events message", JSON.stringify(server));
      if (
        server.action === "CONNECT" &&
        !state.usersOnlineId.includes(server.userId)
      ) {
        state.usersOnlineId.push(server.userId);
      } else if (
        server.action === "DISCONNECT" &&
        state.usersOnlineId.includes(server.userId)
      ) {
        state.usersOnlineId.splice(
          state.usersOnlineId.indexOf(server.userId),
          1
        );
      }
    },
    SOCKET_exception(state, server) {
      console.log("socket exception");
      // console.log("socket exception message", JSON.stringify(server));
      state.socketMessage = server;
    },
    SOCKET_disconnect(state, server) {
      console.log("socket disconnect");
      // console.log("socket disconnect message", JSON.stringify(server));
      state.isConnected = false;
      state.socketMessage = server;
    },
    SET_USERS_ONLINE_INITIAL_STATE(state, usersOnlineId) {
      state.usersOnlineId = usersOnlineId;
    },
  },

  actions: {
    SOCKET_exclusionRevocation(context, server) {
      const currentWhiteLabelId =
        context.rootState.whitelabelCurrencyNabvar.whitelabel._id;
      if (
        currentWhiteLabelId &&
        server?.whitelabelsIds?.includes(currentWhiteLabelId)
      ) {
        if (store.hasModule("app-revocation-list"))
          context.rootState["app-revocation-list"].revocationNotification.push(
            server
          );
        Vue.prototype.$toast({
          component: ToastificationContent,
          props: {
            title: "Revocación",
            text: "Hay una nueva solicitud de revocación",
            icon: "RotateCcwIcon",
            variant: "success",
          },
        });
      }
    },
  },
};
