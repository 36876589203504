import axios from 'axios'

export default {
    namespaced: true,
    state:{
        currencys: []
    },
    mutations: {
        GET_CURRENCYS(state, payload){
            state.currencys = payload
        },
    },
    actions: {
        currencys({ commit, getters }) {
            return new Promise((resolve, reject) => {
                axios.get('currencys')
                .then(res => {
                    commit('GET_CURRENCYS', res.data)
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response)
                })
            })
        },
        store({ commit, getters }, formData) {
            return new Promise((resolve, reject) => {
                axios.post('currencys', formData)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        update({ commit, getters }, formData) {
            return new Promise((resolve, reject) => {
                axios.put(`currencys/${formData._id}`, formData)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        destroy({ commit, getters }, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`currencys/${id}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
    }
}