export default {
  SET_MODELOS_FACTURA (state, response) {
    state.modelosFacturas = response
  },
  ADD_SEGUROS (state, item) {
    state.seguros.push(item)
  },
  DELETE_MODELO_FACTURA (state, id) {
    const indeX = state.modelosFacturas.data.findIndex(p => p.id === id)
    state.modelosFacturas.data.splice(indeX, 1)
  },
}
