import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    namespaced: true,
    state: {
        whitelabel: null,
        currency: null,
        whitelabelsOptions: JSON.parse(localStorage.getItem('whitelabels')),
        userInfo: JSON.parse(localStorage.getItem('userData'))
    },
    mutations,
    actions,
    getters
}
