<template>
  <container :phone="phone" :tablet="talet" :laptop="laptop">
      <slider ref="slider" :options="options" :style="containerStyleParsed">
          <!-- slideritem wrapped package with the components you need -->
          <slideritem v-for="(item,index) in items" :key="index" :style="itemStyleParsed" id='itemSlide'>
            <template v-if="item.href">
              <router-link :to="item.href">
                <img :src="item.img" alt="" id="imgFigure" draggable="false" ondragstart="return false;">
              </router-link>
            </template>
            <template v-else>
              <img :src="item" alt="" id="imgFigure" draggable="false" ondragstart="return false;">
            </template>
          </slideritem>
      </slider>
  </container>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import container from '../container/container.vue'
export default {
  components: {
    slider,
    slideritem,
    container
  },
  props:{
    phone:Object,
    talet:Object,
    laptop:Object,

    items:Array,
    pagination:Boolean, 
    infinite:Boolean,
    show:String,
    slideSpeed:Number,
  },
  data () {
   return {
     options: {
      loopedSlides:this.items.length,
      speed: this['slide-speed'] || 3000,
      loop: this.infinite!==undefined ? this.infinite : false,
      effect:'nest',
      pagination: this.pagination!==undefined ? this.pagination : false,
     },
   }
 },
  computed:
  {
    containerStyleParsed(){
      if(typeof this.containerStyle === 'object'){
        return {
          ...this.containerStyle,
          transition:"all 3s ease 0s"
        }
      }else{
        return this.containerStyle + ';transition:all 3s ease 0s'
      }
    },
    itemStyleParsed(){
      if(typeof this.itemStyle === 'object'){
        return {
          ...this.itemStyle,
          transition:"all 3s ease 0s"
        }
      }else{
        return this.containerStyle + ';transition:all 3s ease 0s'
      }
    },
    Style()
    {
      const imgStyle = {
        "background-image": this.background_image ? `url(${this.background_image})` :'',
        "background-size":"100% 100%",
        "background-position":"center",
        "background-repeat":"no-repeat"
      }
      return {
        "background-color": this.background_color ? this.background_color : '',
        ...imgStyle,
        ...this.style
      }
    },
  }
}
</script>
<style scoped>

.items-section .sec-title {
    margin-bottom: 93px;
}
.title_img img {
    margin: auto;
    margin-top: auto;
    display: block;
    margin-top: -55px;
}
#itemSlide
{
  width:100%;
  touch-action: pan-y;
  margin-left: 10px;
  margin-right: 10px;
  user-select: none;
}
#imgFigure{
  user-select: none;
  display: block;
  width: 100%;
  transform-style: preserve-3d;
  transition: all 300ms ease;
}
.slider-touch{
  touch-action: manipulation;
}
@media screen and (min-width:460px){
  #itemSlide{
    width: 50%;
  }
} 
@media screen and (min-width:920px){
  #itemSlide{
    width: 33.33%;
  }
} 
@media screen and (min-width:1024px){
  #itemSlide{
    width: 25%;
  }
} 
</style>
