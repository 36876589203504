import Vue from "vue";
import VueSocketIO from "vue-socket.io";
import socket_io from "socket.io-client";
import store from "@/store";
const SocketInstance = socket_io.connect(process.env.VUE_APP_SOCKET_URL, {
  transports: ["websocket"],
  autoConnect: false,
  // query: {
  //   token: localStorage.getItem('auth')
  // }
});

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketInstance,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

export function connectSocket() {
  SocketInstance.io.uri = `${
    process.env.VUE_APP_SOCKET_URL
  }/?token=Bearer ${localStorage.getItem("access_token")}`;
  SocketInstance.connect();
}

export function disconnectSocket() {
  console.log("disconnect socket");
  SocketInstance.close();
}

export default SocketInstance;
