import axios from '@/libs/axios'
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Users Hierarchy
    getUsersHierarchy({commit}, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getHierarchi`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
