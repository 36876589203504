import { render, staticRenderFns } from "./client-section.vue?vue&type=template&id=c6361f48&scoped=true"
import script from "./client-section.vue?vue&type=script&lang=js"
export * from "./client-section.vue?vue&type=script&lang=js"
import style0 from "./client-section.vue?vue&type=style&index=0&id=c6361f48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6361f48",
  null
  
)

export default component.exports