import axios from "@/libs/axios";

export default {

    getExclusions(context, filter) {
        console.log(filter)

        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.VUE_APP_URL}/exclusion-system/exclusion-report`, {params: filter}
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

}
