import { render, staticRenderFns } from "./provider-section.vue?vue&type=template&id=3bd21796&scoped=true"
import script from "./provider-section.vue?vue&type=script&lang=js"
export * from "./provider-section.vue?vue&type=script&lang=js"
import style0 from "./provider-section.vue?vue&type=style&index=0&id=3bd21796&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd21796",
  null
  
)

export default component.exports