// axios
import axios from "axios";
import router from "@/router";
import { getUserData } from "@/auth/utils";
import store from "@/store";

const API_URL = process.env.VUE_APP_URL;

let isRefreshing = false;
let refreshSubscribers = [];

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: API_URL,
  // timeout: 1000,
});

// interceptors
axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response } = error;
    console.log(config.url)
    if (response && response.status === 401 && config.url !== "auth/signin") {
      if (!isRefreshing) {
        isRefreshing = true;
        return axios
          .post(API_URL + "/refreshToken", {
            token: store.state.user.refresh_token ,
          })
          .then((response) => {
            // User token update
            store.commit("user/SET_ACCESS_TOKEN", response.data.token);
            localStorage.setItem("access_token", response.data.token);
            // Reintentar la solicitud fallida
            refreshSubscribers.forEach((cb) => cb());
            refreshSubscribers = [];
            isRefreshing = false;
            return axiosIns(error.config);
          })
          .catch((e) => {
            isRefreshing = false;
            refreshSubscribers = []
            // Redirect to log in if refresh fails
            if (router.currentRoute.name !== "auth-login")
              router.push({ name: "auth-login" });
            if (config.url === '/tokenCheck'){
              return  Promise.reject(error)
            }
            return Promise.reject(e);
          });
      } else {
        //Si se está refrescando el token, se almacena la solicitud en un array refreshSubscribers y se devuelve una 
        // nueva promesa que se resolverá una vez que se haya actualizado el token.

        //1- Aquí se crea una nueva promesa.En este caso, se crea una promesa que se resolverá cuando se actualice el token y
        // se reanude la solicitud.

        //2- Se agrega una función al array refreshSubscribers.
        // Esta función se encarga de resolver la promesa una vez que se haya actualizado el token.

        //3= resolve(axiosIns(error.config));: Cuando se resuelva la promesa, se ejecutará esta línea de código, que
        // básicamente reenvía la solicitud original que falló una vez que se haya actualizado el token. Esto se logra
        // llamando a axiosIns(error.config), que es la forma de reintentar la solicitud original con la configuración
        // original que causó el error.

        return new Promise((resolve) => {
          refreshSubscribers.push(() => {
            resolve(axiosIns(error.config));
          });
        });
      }
    }
    return Promise.reject(error);
  }
);

// In each request the token saved in localstorage is used
axiosIns.interceptors.request.use(function (config) {
  config.headers.Authorization =
    "Bearer " + localStorage.getItem("access_token");
  return config;
});

export default axiosIns;
