// auth.js - Auth middleware
// Import the store to access state
import store from "@/store";

// Import helper functions
import { checkUser2FA, getUserData } from "@/auth/utils";

// Export default middleware function
export default function authMiddleware(to, from, next) {
  // Check if route requires authentication
  const protectedRoute = to.matched.some((record) => {
    return record.meta.login === true;
  });

  // If protected route
  if (protectedRoute) {
    // Get user data from storage
    const user = getUserData();

    // Check if user has 2FA enabled
    if (checkUser2FA(user)) {
      // Redirect to log in route
      next({ name: "auth-login" });

      // Set 2FA type in state
      store.commit("verification/SET_COMPONENT_TYPE", user.type2FA);

      // Show 2FA verification modal
      store.commit("verification/SET_VERIFICATION_MODAL", true);

      // Save redirect url for after verification
      store.commit("verification/SET_MODAL_REDIRECT", to.fullPath);
    }
  }

  // Allow navigation to continue
  return next();
}
