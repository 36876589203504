import axios from "@/libs/axios";

export default {
    fetchProducts(ctx, clientId) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/clients/availProd/${clientId}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },
}