<template>
  <section class="feature-style-five mx-auto" :class="ClassContainer" :style="Style" v-if="!hide">
    <div class="container-slot">
      <div class="inner-box-slot">
        <b-row>
          <b-col
            cols="12"
            class="title_img"
          >
            <img :src="img" alt="">
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="12" md="4" class="center">
                <slot></slot> 
              </b-col>
              <b-col
                cols="12"
                md="8"
                class="content-column center m-0 p-0"
              >
                <div class="image-column">
                  <figure class="image image-1 wow slideInRight animated animated animated h-full w-full flex justify-center" data-wow-delay="00ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms;">
                    <img width="100%" :src="content.img_promo" alt="">
                  </figure>
                </div>
              </b-col>
            </b-row>            
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import { BRow, BCol} from 'bootstrap-vue'

export default {
  components:{
    BRow,
    BCol
  },
  props:['hide', 'img', "'class'", 'background_color', 'content',"'style'",'background_image'],
  name:"ImageSlots",
  computed:
  {
    Style()
    {
      return {
        "background-color": this.background_color ? this.background_color : '',
        "background-image": this.background_image ? `url(${this.background_image})` :'',
        ...this.style
      }
    },
    ClassContainer()
    {
      return this.class

    },
  }
}
</script>
<style scoped>
.feature-style-five {
    position: relative;
    padding: 0px 0px 10px 0px;
    display: block;
}
.title_img img {
    margin: auto;
    display: block;
    margin-top: -55px;
}
#content_block_14 .content-box {
    position: relative;
    margin: 30px 0px;
}
figure {
    margin: 0px;
}
#content_block_14 .content-box {
    position: relative;
    margin: 30px 0px;
}
.btn-box {
    text-align: center;
}

#content_block_14 .content-box .btn-box .theme-btn {
    text-transform: uppercase;
    color: #fff !important;
    font-weight: 800;
}
#content_block_14 .content-box {
    position: relative;
    margin: 30px 0px;
}

img {
    vertical-align: middle;
    border-style: none;
}
.center{
  display: grid;
  place-items: center;
}

</style>
