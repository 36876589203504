<template>
  <div :class="ClassContainer" :style="Style" v-if="!hide">
    <img class="d-block" :src="img" width="100%">
    </div>
</template>

<script>


export default {
  name:"Banner",
  props:['hide', 'img', "'class'",'background_color'],
  computed:
  {
    Style()
    {
      return {
        "background-color": this.background_color ? this.background_color : '',
        }
    },
    ClassContainer()
    {
      return this.class

    },
  }
}
</script>
<style scoped>
#itemCarousel{
  transition: -webkit-transform .6s ease;
  transition: transform .6s ease;
  transition: transform .6s ease,-webkit-transform .6s ease;
}
</style>
