<template>
  <section class="feature-style-five mx-auto" :class="ClassContainer" :style="Style" v-if="!hide">
    <div class="container">
      <div class="inner-box">
        <v-row 
          align="center"
          justify="center"
          class=""
        >
          <v-col
            cols="12"
            class="title_img"
          >
            <img :src="img"   alt="">
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="content-column w-full -mt-7"
          >
            <div class="image-column">
              <figure class="image image-1 wow slideInRight animated animated animated w-full flex lg:justify-start md:justify-center" data-wow-delay="00ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms;">
                <img :src="content.img_promo"   alt="">
              </figure>
            </div>

          </v-col>
          <v-col
            cols="12"
            md="4"
            class="content-column w-full -mt-20"
          >
            <div id="content_block_14">
              <div class="content-box wow fadeInUp animated animated animated" data-wow-delay="300ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 300ms;">
                <div class="btn-box">
                  <p href="#" class="link theme-btn">
                  sing up
                    <i>
                      <img :src="content.img_icon" alt="">
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script>


export default {
  props:['hide', 'img', "'class'", 'background_color', 'content',"'style'",'bacground_image','style'],
  name:"FeactureSection",
  computed:
  {
    Style()
    {
      return {
        "background-color": this.background_color ? this.background_color : '',
        "background-image": this.background_image ? `url(${this.background_image})` :'',
        ...this.style
      }
    },
    ClassContainer()
    {
      return this.class

    },
  }
}
</script>
<style scoped>
.feature-style-five {
    position: relative;
    padding: 110px 0px 10px 0px;
    display: block;
}
.title_img img {
    margin: auto;
    display: block;
    margin-top: -55px;
}
#content_block_14 .content-box {
    position: relative;
    margin: 30px 0px;
}
figure {
    margin: 0px;
}
#content_block_14 .content-box {
    position: relative;
    margin: 30px 0px;
}
.btn-box {
    text-align: center;
}

#content_block_14 .content-box .btn-box .theme-btn {
    text-transform: uppercase;
    color: #fff !important;
    font-weight: 800;
}
#content_block_14 .content-box {
    position: relative;
    margin: 30px 0px;
}
.theme-btn i {
    position: absolute;
    top: -5px;
    left: -60px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    border-radius: 50%;
    transition: all 900ms ease;
}
img {
    vertical-align: middle;
    border-style: none;
}

</style>
