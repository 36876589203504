import axios from '@axios';
export default {
    namespaced: true,
    state:{

    },
    actions: {
        /**
         * Create and Update limitation
         */
        async paymentLimitation ({commit},params) {
            return await axios.post('/payment-limitation', params);
        },
        /**
         *
         * @param commit
         * @param params Object with WhiteLabel id
         * @returns {Promise}
         */
        async deletePaymentLimitation ({commit},params) {
            return await axios.delete(`/payment-limitation/${params.id}`);
        },
        /**
         *
         * @param commit
         * @param params Object with WhiteLabel id
         * @returns {Promise}
         */
        getPaymentLimitation ({commit},params) {
            return new Promise( (resolve, reject) => {
                axios.get(`/payment-limitation/${params.id}`).then((response) => {
                    resolve(response.data);
                });

            })

        }
    }

}