import i18n from '@/libs/i18n'

export function axiosErrorHandle(error) {
    let message = "";
    if (!error) message = 'Error'
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    else if (error.response) message = error.response.data.message;
    // The request was made but no response was received
    else if (error.request) message = i18n.t('not_establish_communication_server');
    // Something happened in setting up the request that triggered an Error
    else message = error.message;
    return message;
}