<template>
  <b-carousel 
    :class="ClassContainer" 
    :interval="6000" 
    height="auto" 
    :style="Style"
    controls 
    v-if="!hide"
  >
    <b-carousel-slide
      v-for="(src, i) in img"
      :key="i"
      :img-src="src"
      width="100%"
    >
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'

export default {
  components:{
    BCarousel,
    BCarouselSlide
  },
  name:"Carrousel",
  props:['hide', 'img', "'class'", 'background_color'],
  computed:
  {
    Style()
    {
      return {
        "background-color": this.background_color ? this.background_color : 'white',
        }
    },
    ClassContainer()
    {
      return this.class

    },
  }
}
</script>
<style scoped>
</style>
