import axios from '@/libs/axios'
const APP_URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {
    homepage:{},
  },
  getters: {},
  mutations: {
    updateHomepage(state,newhome){
      state.homepage = newhome
    }
  },
  actions: {
    treeHandlerDelete() {
      const search = (tree,paths) => {
        let path = Number(paths[0])
        if (paths.length==1) {
          tree.splice(path,1)
        } else {
          if (tree[path].child){
            return search(tree[path].child, paths.slice(1))
          }else{
            tree.splice(path,1)
          }
        }
      }
      const home= homePage.value
      search(home.layout,path.value)
      homePage.value = home
      path.value=null
    },
    treeHandlerPush(name){
      const node = {
        el:name,
        props:{},
        hide:false
      }
      const search = (tree,paths)=>{
        let path = Number(paths[0])
        if (paths.length==1){
          if(tree[path].child!==undefined){
            tree[path].child.push(node)
          }else{
            tree[path].child=[node]
          }
        }else{
          if (tree[path].child){
            return search(tree[path].child, paths.slice(1))
          }else{
            if(tree[path].child!==undefined){
              tree[path].child.push(node)
            }else{
              tree[path].child=[node]
            }
          }
        }
      }
      
      if (path.value[0] == '-'){
        home.layout.push(node)
      } else {
        search(homePage.value.layout,path.value)
      }
      console.log(homePage.value)
    },
    fetchHomePages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/homepages`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHomePage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/homepages/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHomePageOptions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/homepages/options`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addHomePage(ctx, homePageData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/homepages`,  homePageData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },   
    updateHomePage(ctx, { id, homePageData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${APP_URL}/homepages/${id}`, { homePage: homePageData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeHomePage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${APP_URL}/homepages/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHomePageImage(ctx,  id ) {
      return new Promise((resolve, reject) => {
        axios
            .get(`${APP_URL}/files/${id}?path=homePage`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
