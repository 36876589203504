<template>
  <section class="feature-style-five mx-auto -mt-5 pt-0" :class="ClassContainer" :style="Style" v-if="!hide">
    <div class="container">
      <div class="inner-box">
        <b-row 
          align="center"
          justify="space-between"
          class=""
        >
          <b-col
            md="12"
            lg="12"
            class="title_img"
          >
            <img :src="img"   alt="">
          </b-col>
          <b-col
            cols="12"
            md="5"
            class="content-column w-full mr-0 lg:-mt-20"
          >
            <div id="content_block_14">
              <div class="content-box wow fadeInUp animated animated animated" data-wow-delay="300ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 300ms;">
                <div class="btn-box">
                  <p class="link theme-btn">
                    play now
                    <i>
                      <img :src="content.img_icon" alt=""  >
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </b-col>

          <b-col
            cols="12"
            md="7"
            class="content-column w-full -mt-7"
          >
            <div class="image-column">
              <figure class="image image-1 wow slideInRight animated animated animated w-full flex lg:justify-end md:justify-center" data-wow-delay="00ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms;">
                <img   :src="content.img_promo" alt="">
              </figure>
            </div>
          </b-col>
        </b-row>

      </div>
    </div>
  </section>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name:"ProviderSection",
  components:{
    BCol,
    BRow
  },
  props:['hide', 'img', "'class'", 'background_color', 'content','background_image',"'style'"],
  computed:
  {
    Style()
    {
      return {
        "background-color": this.background_color ? this.background_color : '',
        "background-image": this.background_image ? `url(${this.background_image})` :'',
        ...this.style
      }
    },
    ClassContainer()
    {
      return this.class

    },
  }
}
</script>
<style scoped>
.feature-style-five {
    position: relative;
    padding: 110px 0px 10px 0px;
    display: block;
}
.title_img img {
    margin: auto;
    display: block;
    margin-top: -55px;
}
#content_block_14 .content-box {
    position: relative;
    margin: 30px 0px;
}
figure {
    margin: 0px;
}
#content_block_14 .content-box {
    position: relative;
    margin: 30px 0px;
}
.btn-box {
    text-align: center;
}

#content_block_14 .content-box .btn-box .theme-btn {
    text-transform: uppercase;
    color: #fff !important;
    font-weight: 800;
}
#content_block_14 .content-box {
    position: relative;
    margin: 30px 0px;
}
.theme-btn i {
    position: absolute;
    top: -5px;
    left: -60px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    border-radius: 50%;
    transition: all 900ms ease;
}
img {
    vertical-align: middle;
    border-style: none;
}

</style>
