export default [
  {
    path: '/',
    name: 'dashboard',
    meta:{
      login: true,
    },
    component: () => import('@/views/dashboard/DashboardView.vue'),
  },
]
