import axios from 'axios'

export default {
    namespaced: true,
    state: {
        clients: []
    },
    mutations: {
        GET_CLIENTS(state, payload){
            state.clients = payload
        },
    },
    actions: {
        clients({ commit, getters }) {
            return new Promise((resolve, reject) => {
                axios.get('clients')
                .then(res => {
                    commit('GET_CLIENTS', res.data)
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response)
                })
            })
        },
        show({ commit, getters }, _id) {
            return new Promise((resolve, reject) => {
                axios.get(`clients/${_id}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response)
                })
            })
        },
        store({ commit, getters }, formData) {
            return new Promise((resolve, reject) => {
                axios.post('clients', formData)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        update({ commit, getters }, formData) {
            return new Promise((resolve, reject) => {
                axios.put(`clients/${formData._id}`, formData)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        destroy({ commit, getters }, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`clients/${id}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        transactionsByCustomer({ commit, getters }, {clientId, query}) {
            return new Promise((resolve, reject) => {
                axios.get( `client/${clientId}/transactions?createdAt=${query.createdAt}&currency=${query.currencyId}&from=${query.from}&reference=${query.reference}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response)
                })
            })
        },
    }
}