import axios from 'axios'

export default {
    namespaced: true,
    state:{
        wallets:[],
        wallet:{},
        transactionsWallet: [],
    },
    mutations: {
        GET_WALLETS(state, payload){
            state.wallets = payload
        },
        DELETE_WALLET(state, id){
            const indexWallet = state.wallets.findIndex(p => p._id == id)
            state.wallets.splice(indexWallet, 1)
        },
        SHOW_BALANCE(state, payload){
            const indexWallet = state.wallets.findIndex(p => p._id == payload.id)
            state.wallets[indexWallet].balance = payload
        },
        SHOW_BALANCE_MOD(state, payload){
            state.wallet.balance = payload
        },
        SELECT_WALLET(state, payload){
            state.wallet = payload
        },
        SHOW_TRANSACTIONS_WALLET(state, payload){
            state.transactionsWallet = payload
        }
    },
    getters:{
        getWallets(state){
            // ordenar por fecha
            console.log(state.wallets)
            return state.wallets.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt)
            })
        },
    },
    actions: {
        wallets({ commit, getters }) {
            return new Promise((resolve, reject) => {
                axios.get('wallets')
                .then(res => {
                    commit('GET_WALLETS', res.data)
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response)
                })
            })
        },
        show({ commit, getters }, _id) {
            return new Promise((resolve, reject) => {
                axios.get(`wallets/${_id}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response)
                })
            })
        },
        store({ commit, getters }, formData) {
            return new Promise((resolve, reject) => {
                axios.post('wallets', formData)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        setCreditOrDebit({ commit, getters }, data) {
            return new Promise((resolve, reject) => {
                axios.post(`wallet/${data.walletId}/${data.typeTransaction}?currency=${data.currency}`, data.transaction)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        balance({ commit, getters }, data) {
            return new Promise((resolve, reject) => {
                if(!data.currencyId){
                    commit('SHOW_BALANCE', {balance: 0, currency: data.currencyId, id: data.walletId})
                    resolve("No Currency")
                    return;
                };
                axios.get(`wallet/${data.walletId}/balance?currency=${data.currencyId}`)
                .then(res => {
                    commit('SHOW_BALANCE', {balance: res.data.balance, currency: data.currencyId, id: data.walletId})
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getBalanceMod({ commit }, {walletId, currency}) {
            return new Promise((resolve, reject) => {
                axios.get(`wallet/${walletId}/balance?currency=${currency}`)
                .then(res => {
                    commit('SHOW_BALANCE_MOD', {balance: res.data.balance, currency})
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        addCurrency({ commit, getters }, { _id, currencies}) {
            return new Promise((resolve, reject) => {
                axios.post(`wallet/${_id}/addcurrencys`, {currencies} )
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },        
        destroy({ commit, getters }, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`wallets/${id}`)
                .then(res => {
                    commit('DELETE_WALLET', id)
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getWalletTransactions({ commit, getters }, id) {
            return new Promise((resolve, reject) => {
                axios.get(`wallet/${id}/transactions`)
                .then(res => {
                    commit('SHOW_TRANSACTIONS_WALLET', res.data.data)
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        addBonus({ commit, getters }, formData) {
            return new Promise((resolve, reject) => {
                axios.post('bonus/add', formData)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
        debitWithdrawalsBonus({ commit, getters }, formData) {
            return new Promise((resolve, reject) => {
                axios.post(`bonus/${formData.bonusId}/${formData.from}`, formData.data)
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
            })
        },
    }
}