export default {
    WHITELABELS_OPTIONS(state){
        return state.whitelabelsOptions
    },
    CURRENCY_OPTIONS(state, getters, rootState){
        if(rootState.user.user.typeUser == 'Root'){
            return state.whitelabel?.currencies || []
        }else{
            const currencyUserLogueado = rootState.user.user.currencies || []
            const currencyWhitelabel = state.whitelabel?.currencies || []
            return  currencyWhitelabel.filter(currency => currencyUserLogueado.includes(currency))
        }
    },
}